var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"whole"},[_c('Nav'),_c('div',{staticClass:"catalog-whole desktop-present"},[_c('div',{staticClass:"catalogtitle"},[_c('div',{staticClass:"icon"}),_vm._v(" 首页 >> "+_vm._s(_vm.catalogTitle)+" ")]),_c('div',{staticClass:"catalog-con"},[_vm._l((_vm.newsList),function(p,index){return [(index == 1)?_c('div',{key:'catalog_inner_' + index,staticClass:"adOne"},[_c('a',{attrs:{"href":_vm.adOneLink,"target":"_blank"}},[_c('img',{staticClass:"ad-img",attrs:{"src":_vm.adOneImg}})])]):_vm._e(),(index == 3)?_c('div',{key:'catalog_inner_' + index,staticClass:"adTwo"},[_c('a',{attrs:{"href":_vm.adTwoLink,"target":"_blank"}},[_c('img',{staticClass:"ad-img",attrs:{"src":_vm.adTwoImg}})])]):_vm._e(),(index == 5)?_c('div',{key:'catalog_inner_' + index,staticClass:"adThree"},[_c('a',{attrs:{"href":_vm.adThreeLink,"target":"_blank"}},[_c('img',{staticClass:"ad-img",attrs:{"src":_vm.adThreeImg}})])]):_vm._e(),(index == 2)?_c('div',{key:'catalog_inner_' + index,staticClass:"adFour"},[_c('a',{attrs:{"href":_vm.adFourLink,"target":"_blank"}},[_c('img',{staticClass:"ad-img",attrs:{"src":_vm.adFourImg}})])]):_vm._e(),(index == 4)?_c('div',{key:'catalog_inner_' + index,staticClass:"adFive"},[_c('a',{attrs:{"href":_vm.adFiveLink,"target":"_blank"}},[_c('img',{staticClass:"ad-img",attrs:{"src":_vm.adFiveImg}})])]):_vm._e(),_c('div',{key:'catalog_inner_' + index,staticClass:"catalog-list"},[_c('div',{staticClass:"catalog-title"},[_c('div',{staticClass:"title-left"},[_vm._v(_vm._s(p.title))]),_c('div',{staticClass:"title-right",on:{"click":function($event){return _vm.onGoCnews(p.id)}}},[_vm._v("更多 >>")])]),_c('div',{key:p.id,staticClass:"cataloag-content",class:{
              fixtopOne: (index + 1) % 3 == 1,
              fixtopTwo: (index + 1) % 3 == 2,
              fixtopThree: (index + 1) % 3 == 0,
            }},_vm._l((p.posts),function(k){return _c('div',{key:'nav_' + k.postId,staticClass:"catalog-detail"},[_c('div',{staticClass:"img",style:({ backgroundImage: "url(" + k.imgURL + ")" }),on:{"click":function($event){return _vm.onLookPost(k.postId)}}}),_c('div',{staticClass:"title",on:{"click":function($event){return _vm.onLookPost(k.postId)}}},[_vm._v(" "+_vm._s(k.title)+" ")])])}),0)])]})],2)]),_c('div',{staticClass:"catalog-whole mobile-present"},[_c('div',{staticClass:"catalogtitle"},[_c('div',{staticClass:"icon"}),_vm._v(" 首页 >> "+_vm._s(_vm.catalogTitle)+" ")]),_c('div',{staticClass:"catalog-con"},_vm._l((_vm.newsList),function(p,index){return _c('div',{key:p.id,staticClass:"catalog-list"},[_c('div',{staticClass:"catalog-title"},[_c('div',{staticClass:"title-left"},[_vm._v(_vm._s(p.title))]),_c('div',{staticClass:"title-right",on:{"click":function($event){return _vm.onGoCnews(p.id)}}},[_vm._v("更多 >>")])]),_c('div',{staticClass:"cataloag-content",class:{
            fixtopOne: (index + 1) % 3 == 1,
            fixtopTwo: (index + 1) % 3 == 2,
            fixtopThree: (index + 1) % 3 == 0,
          }},_vm._l((p.posts),function(k){return _c('div',{key:'nav_' + k.postId,staticClass:"catalog-detail"},[_c('div',{staticClass:"img",style:({ backgroundImage: "url(" + k.imgURL + ")" }),on:{"click":function($event){return _vm.onLookPost(k.postId)}}}),_c('div',{staticClass:"title",on:{"click":function($event){return _vm.onLookPost(k.postId)}}},[_vm._v(" "+_vm._s(k.title)+" ")])])}),0)])}),0)]),_c('Bottom')],1)}
var staticRenderFns = []

export { render, staticRenderFns }