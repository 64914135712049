<template>
  <div class="whole">
    <Nav></Nav>
    <div class="catalog-whole desktop-present">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页 >> {{ catalogTitle }}
      </div>
      <div class="catalog-con">
        <template v-for="(p, index) in newsList">
          <div class="adOne" :key="'catalog_inner_' + index" v-if="index == 1">
            <a :href="adOneLink" target="_blank">
              <img class="ad-img" :src="adOneImg" />
            </a>
          </div>
          <div class="adTwo" :key="'catalog_inner_' + index" v-if="index == 3">
            <a :href="adTwoLink" target="_blank">
              <img class="ad-img" :src="adTwoImg" />
            </a>
          </div>
          <div class="adThree" :key="'catalog_inner_' + index" v-if="index == 5">
            <a :href="adThreeLink" target="_blank">
              <img class="ad-img" :src="adThreeImg" />
            </a>
          </div>
          <div class="adFour" :key="'catalog_inner_' + index" v-if="index == 2">
            <a :href="adFourLink" target="_blank">
              <img class="ad-img" :src="adFourImg" />
            </a>
          </div>
          <div class="adFive" :key="'catalog_inner_' + index" v-if="index == 4">
            <a :href="adFiveLink" target="_blank">
              <img class="ad-img" :src="adFiveImg" />
            </a>
          </div>
          <div class="catalog-list" :key="'catalog_inner_' + index">
            <div class="catalog-title">
              <div class="title-left">{{ p.title }}</div>
              <div class="title-right" @click="onGoCnews(p.id)">更多 >></div>
            </div>
            <div
              :key="p.id"
              class="cataloag-content"
              v-bind:class="{
                fixtopOne: (index + 1) % 3 == 1,
                fixtopTwo: (index + 1) % 3 == 2,
                fixtopThree: (index + 1) % 3 == 0,
              }"
            >
              <div class="catalog-detail" v-for="k in p.posts" :key="'nav_' + k.postId">
                <div class="img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onLookPost(k.postId)"></div>
                <div class="title" @click="onLookPost(k.postId)">
                  {{ k.title }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="catalog-whole mobile-present">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页 >> {{ catalogTitle }}
      </div>
      <div class="catalog-con">
        <div class="catalog-list" v-for="(p, index) in newsList" :key="p.id">
          <div class="catalog-title">
            <div class="title-left">{{ p.title }}</div>
            <div class="title-right" @click="onGoCnews(p.id)">更多 >></div>
          </div>
          <div
            class="cataloag-content"
            v-bind:class="{
              fixtopOne: (index + 1) % 3 == 1,
              fixtopTwo: (index + 1) % 3 == 2,
              fixtopThree: (index + 1) % 3 == 0,
            }"
          >
            <div class="catalog-detail" v-for="k in p.posts" :key="'nav_' + k.postId">
              <div class="img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onLookPost(k.postId)"></div>
              <div class="title" @click="onLookPost(k.postId)">
                {{ k.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      newsList: [], // 新闻图片的所有
      catalogTitle: '',
      adOneImg: '', // 第一个广告
      adTwoImg: '', // 第一个广告
      adThreeImg: '', // 第一个广告
      adFourImg: '', // 第4个
      adFiveImg: '', //第5个
    }
  },
  created() {
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.reload()
    }
  },
  methods: {
    reload() {
      var thiz = this
      console.log('quet', thiz.$route.query)
      thiz.catalogTitle = thiz.$route.query.title

      RD.ad()
        .id(13)
        .one()
        .then((data) => {
          thiz.adOneImg = RD.ad().id(13).image_URL()
          thiz.adOneLink = data.link
        })

      RD.ad()
        .id(7)
        .one()
        .then((data) => {
          thiz.adTwoImg = RD.ad().id(7).image_URL()
          thiz.adTwoLink = data.link
        })

      RD.ad()
        .id(9)
        .one()
        .then((data) => {
          thiz.adThreeImg = RD.ad().id(9).image_URL()
          thiz.adThreeLink = data.link
        })

      RD.ad()
        .id(15)
        .one()
        .then((data) => {
          thiz.adFourImg = RD.ad().id(15).image_URL()
          thiz.adFourLink = data.link
        })

      RD.ad()
        .id(16)
        .one()
        .then((data) => {
          thiz.adFiveImg = RD.ad().id(16).image_URL()
          thiz.adFiveLink = data.link
        })

      RD.index()
        .catalogByName(thiz.catalogTitle)
        .then((data) => {
          console.log('catalogdata', data)
          if (data.length == 0) {
            thiz.$message('当前栏目无稿件')
          }
          data.forEach((i, index) => {
            i.posts.forEach((k) => {
              if (k.frontPicId) {
                k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
              } else {
                k.imgURL = RD.video().id(k.postId).poster_URL()
              }
            })
            var indexOrder = index + 1
          })
          thiz.newsList = data
        })
    },
    // 更多
    onGoCnews(navId) {
      if (navId == 130 || navId == 131 || navId == 132 || navId == 133 || navId == 134) {
        this.$router.push({
          path: '/qiancunPost.html',
          query: { id: navId },
        })
      } else {
        this.$router.push({
          path: '/fixTopic.html',
          query: { id: navId },
        })
      }
    },
    onLookPost(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>

<style scoped lang="less">
.catalog-whole.desktop-present {
  height: auto;
  background-color: #fff;
  .catalogtitle {
    height: 4rem;
    text-align: left;
    line-height: 4rem;
    width: 120rem;
    margin: 0 auto;
    font-size: 16px;
    padding-top: 1.5rem;
    display: flex;
    border-bottom: 0.1rem solid #bfbfbf;
    .icon {
      width: 2rem;
      height: 2rem;
      background: no-repeat url(../assets/weizhi.png);
      background-size: cover;
      margin-right: 1rem;
      margin-top: 10px;
    }
  }
  .catalog-con {
    width: 120rem;
    margin: 3rem auto;
    height: 100%;
    .adOne,
    .adTwo,
    .adThree,
    .adFour,
    .adFive {
      width: 120rem;
      margin: 2rem auto;
      .ad-img {
        width: 100%;
      }
    }
    .catalog-list {
      height: 50rem;
      width: 100%;
      margin-bottom: 3rem;
      background-color: #fff;

      .catalog-title {
        height: 4rem;
        font-size: 16px;
        line-height: 4rem;
        text-align: left;
        font-weight: bold;
        position: relative;
        .title-left {
          position: absolute;
          left: 1rem;
          width: 20rem;
        }
        .title-right {
          position: absolute;
          right: 3.4rem;
          top: 0rem;
          width: 6.7rem;
          cursor: pointer;
          color: #9b9b9b;
          .el-icon-right {
            font-size: 2.8rem;
          }
        }
      }
      .cataloag-content {
        position: relative;
        height: 45rem;
        .catalog-detail {
          .img {
            height: 100%;
            width: 100%;
            background: no-repeat 50% / cover;
          }
        }
      }
      .fixtopOne {
        .catalog-detail {
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 4.3rem;
            line-height: 4.3rem;
            transition: all 200ms ease-in-out;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .catalog-detail:nth-child(1) {
          width: 59.6rem;
          height: 44.1rem;
          position: absolute;
          top: 0rem;
          left: 0rem;
        }
        .catalog-detail:nth-child(2) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 60.5rem;
        }
        .catalog-detail:nth-child(3) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 90.8rem;
        }
        .catalog-detail:nth-child(4) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.5rem;
          left: 60.5rem;
        }
        .catalog-detail:nth-child(5) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.5rem;
          left: 90.8rem;
        }
        // .catalog-detail:nth-child(6),
        // .catalog-detail:nth-child(7) {
        //   width: 27.6rem;
        //   height: 20rem;
        //   position: absolute;
        //   top: 22.5rem;
        //   left: 192.4rem;
        // }
        .catalog-detail:nth-child(1n + 6) {
          display: none;
        }
      }
      .fixtopTwo {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .catalog-detail {
          width: 29.3rem;
          height: 21.4rem;
          position: relative;
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 4.3rem;
            transition: all 200ms ease-in-out;
            line-height: 4.3rem;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .catalog-detail:nth-child(1n + 9) {
          display: none;
        }
      }
      .fixtopThree {
        .catalog-detail {
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 4.3rem;
            line-height: 4.3rem;
            transition: all 200ms ease-in-out;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .catalog-detail:nth-child(1) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 0rem;
        }
        .catalog-detail:nth-child(2) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 0rem;
          left: 30.3rem;
        }
        .catalog-detail:nth-child(3) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.6rem;
          left: 0rem;
        }
        .catalog-detail:nth-child(4) {
          width: 29.3rem;
          height: 21.6rem;
          position: absolute;
          top: 22.6rem;
          left: 30.3rem;
        }
        .catalog-detail:nth-child(5) {
          width: 59.6rem;
          height: 44.1rem;
          position: absolute;
          top: 0rem;
          left: 60.6rem;
        }
        .catalog-detail:nth-child(6),
        .catalog-detail:nth-child(7),
        .catalog-detail:nth-child(8) {
          width: 57.1rem;
          height: 45rem;
          position: absolute;
          top: 0rem;
          left: 162.8rem;
        }
        .catalog-detail:nth-child(1n + 6) {
          display: none;
        }
      }
    }
  }
}
.catalog-whole.mobile-present {
  height: auto;
  background-color: #fff;
  width: 750px;
  .catalogtitle {
    height: 4rem;
    text-align: left;
    width: 750px;
    margin: 0 auto;
    font-size: 1.2rem;
    padding-top: 1.5rem;
    display: flex;
    border-bottom: 0.1rem solid #bfbfbf;
    .icon {
      width: 2rem;
      height: 2rem;
      background: no-repeat url(../assets/weizhi.png);
      background-size: cover;
      margin-right: 1rem;
    }
  }
  .catalog-con {
    width: 750px;
    margin: 3rem auto;
    height: 100%;
    overflow: hidden;
    .catalog-list {
      height: 300px;
      width: 100%;
      margin-bottom: 20px;
      background-color: #fff;

      .catalog-title {
        height: 4rem;
        font-size: 16px;
        line-height: 4rem;
        text-align: left;
        font-weight: bold;
        position: relative;
        .title-left {
          position: absolute;
          left: 1rem;
          width: 20rem;
          font-size: 16px;
        }
        .title-right {
          position: absolute;
          right: 3.4rem;
          top: 0rem;
          width: 6.7rem;
          cursor: pointer;
          color: #9b9b9b;
          .el-icon-right {
            font-size: 2.8rem;
          }
        }
      }
      .cataloag-content {
        position: relative;
        height: 270px;
        .catalog-detail {
          .img {
            height: 100%;
            width: 100%;
            background: no-repeat 50% / cover;
          }
        }
      }
      .fixtopOne {
        .catalog-detail {
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 4.3rem;
            line-height: 4.3rem;
            transition: all 200ms ease-in-out;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .catalog-detail:nth-child(1) {
          width: 357px;
          height: 264px;
          position: absolute;
          top: 0rem;
          left: 0rem;
        }
        .catalog-detail:nth-child(2) {
          width: 175px;
          height: 129px;
          position: absolute;
          top: 0rem;
          left: 365px;
        }
        .catalog-detail:nth-child(3) {
          width: 175px;
          height: 129px;
          position: absolute;
          top: 0rem;
          left: 548px;
        }
        .catalog-detail:nth-child(4) {
          width: 175px;
          height: 129px;
          position: absolute;
          top: 135px;
          left: 365px;
        }
        .catalog-detail:nth-child(5) {
          width: 175px;
          height: 129px;
          position: absolute;
          top: 135px;
          left: 548px;
        }
        .catalog-detail:nth-child(6),
        .catalog-detail:nth-child(7),
        .catalog-detail:nth-child(8) {
          width: 27.6rem;
          height: 20rem;
          position: absolute;
          top: 22.5rem;
          left: 192.4rem;
        }
      }
      .fixtopTwo {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .catalog-detail {
          width: 180px;
          height: 129px;
          position: relative;
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 28px;
            transition: all 200ms ease-in-out;
            line-height: 28px;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .catalog-detail:nth-child(1n + 9) {
          display: none;
        }
      }
      .fixtopThree {
        .catalog-detail {
          overflow: hidden;
          .img {
            cursor: pointer;
            transition: all 200ms ease-in-out;
          }
          .title {
            height: 4.3rem;
            line-height: 4.3rem;
            transition: all 200ms ease-in-out;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            color: #fff;
            cursor: pointer;
          }
        }
        .catalog-detail:nth-child(1) {
          width: 175px;
          height: 129px;
          position: absolute;
          top: 0px;
          left: 10px;
        }
        .catalog-detail:nth-child(2) {
          width: 175px;
          height: 129px;
          position: absolute;
          top: 0rem;
          left: 193px;
        }
        .catalog-detail:nth-child(3) {
          width: 175px;
          height: 129px;
          position: absolute;
          top: 136px;
          left: 10px;
        }
        .catalog-detail:nth-child(4) {
          width: 175px;
          height: 129px;
          position: absolute;
          top: 136px;
          left: 193px;
        }
        .catalog-detail:nth-child(5) {
          width: 357px;
          height: 264px;
          position: absolute;
          top: 0rem;
          left: 378px;
        }
        .catalog-detail:nth-child(6),
        .catalog-detail:nth-child(7),
        .catalog-detail:nth-child(8) {
          width: 57.1rem;
          height: 45rem;
          position: absolute;
          top: 0rem;
          left: 162.8rem;
        }
      }
    }
    .adOne {
      .img {
        object-fit: cover;
      }
    }
  }
}
.catalog-detail:hover {
  .img {
    transform: scale(1.05);
  }
  .title {
    opacity: 1;
    font-size: 1.6rem;
  }
}
</style>
